import React from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { axiosInstance } from "../../../services/axiosInstance";
import Loader from "../../../component/Loader";
const baseURL = process.env.REACT_APP_GATEWAY_URL;

const Logout = () => {
  const navigate = useNavigate();
  const getRevokToken = () => {
    const data = {
      clientName: "artemis",
    };
    axiosInstance
      .post(`${baseURL}/secured/revoke-token`, { data })
      .then((res) => {
        if (res?.data?.data === "Token revoked successfully") {
          localStorage.removeItem("user");
          localStorage.removeItem("token");
          delete axios.defaults.headers.common.Authorization;
        }
        window.location.href = "/";
      });
  };
  useEffect(() => {
    getRevokToken();
  }, []);
  return (
    <div>
      <Loader />
    </div>
  );
};
export default Logout;
