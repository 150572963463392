import {
  Autocomplete,
  Box,
  Button,
  Drawer,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  addVerttical,
  adduserinteam,
  getCompany,
  getTeams,
  getsecureuser,
  updateVerttical,
  updateuserinteam,
} from "../../services";
import { enqueueSnackbar } from "notistack";

const validationSchema = Yup.object().shape({
  user: Yup.object().required("user is required"),
  teams: Yup.object().required("team is required"),
});

const VerticalDrawer = ({
  isDrawerOpen,
  setDefaultValues,
  setIsDrawerOpen,
  defaultValues,
  getDataRender,
}) => {
  const defaultValuesLength =
    defaultValues && Object.keys(defaultValues).length;

  const [isButtonDisabled, setButtonDisabled] = useState(false);
  // const [company, setCompany] = useState([]);
  const [team, setTeam] = useState([]);
  const [user, setuser] = useState([]);

  useEffect(() => {
    if (defaultValues !== undefined && Object.keys(defaultValues).length) {
      setFieldValues(defaultValues);
    } else {
      formik.resetForm();
    }
  }, [defaultValues]);

  // const getAllCompany = async () => {
  //   const { data } = await getCompany();
  //   if (data?.data?.companies) {
  //     setCompany(data?.data?.companies);
  //   }
  // };

  const getAllTeams = async () => {
    const { data } = await getTeams();
    if (data?.data?.teams) {
      setTeam(data?.data?.teams);
    }
  };
  const getAlluser = async () => {
    const { data } = await getsecureuser();
    console.log(data.data);
    if (data?.data) {
      const modifiedData = data?.data.filter(
        (user) => user.roles[0] !== "Super Admin"
      );
      setuser(modifiedData);
    }
  };
  useEffect(() => {
    // getAllCompany();
    getAllTeams();
    getAlluser();
  }, []);

  const setFieldValues = (values) => {
    console.log(team[0]._id, "team,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,");
    console.log(values, "valuss,,,,,,,,,,,,,,,,,,,,,");

    const selectedTeams = team.find((cur) => cur?._id === values?.team?._id);
    console.log(selectedTeams);
    formik.setFieldValue("teams", selectedTeams || null);
    const selectedUser = user.find((cur) => cur.email === values?.email);
    formik.setFieldValue("user", selectedUser || null);
  };

  const formik = useFormik({
    initialValues: {
      teams: null,
      user: null,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log(formik.values, "formik.vaules........................");
      const data = {
        team: values.teams._id,
        data: {
          email: values.user.email,
        },
      };

      try {
        setButtonDisabled(true);
        if (defaultValuesLength === 0) {
          const res = await adduserinteam({ ...data });
          if (res) {
            setIsDrawerOpen(false);
            formik.resetForm();
            setDefaultValues({});
            getDataRender();
          }
        } else {
          const updateData = {
            team: values.teams._id,
          };
          const res = await updateuserinteam(
            { ...updateData },
            defaultValues._id
          );
          if (res) {
            setIsDrawerOpen(false);
            formik.resetForm();
            setDefaultValues({});
            getDataRender();
          }
        }
      } catch (error) {
        console.error("Error submitting vertical data: ", error);
        enqueueSnackbar(`${error}`, {
          variant: "error",
        });
      } finally {
        setButtonDisabled(false);
      }
    },
  });
  console.log(formik.values, "formik.values.............................");
  return (
    <Drawer anchor="right" open={isDrawerOpen}>
      <Box
        sx={{ m: 1, width: "20vw", margin: "24px" }}
        noValidate
        autoComplete="off"
      >
        <div>
          <form onSubmit={formik.handleSubmit}>
            <Typography
              sx={{
                fontWeight: "600",
                fontSize: "1.3rem",
                mt: 4,
              }}
            >
              {defaultValuesLength ? "Edit" : "Add"} user Name
            </Typography>
            <Box>
              <div style={{ width: "330px" }}>
                <Autocomplete
                  disabled={defaultValuesLength}
                  id="user"
                  name="user"
                  disableClearable
                  options={user}
                  getOptionLabel={(option) => option?.name}
                  value={formik?.values?.user}
                  onChange={(_, newValue) => {
                    formik.setFieldValue("user", newValue);
                  }}
                  onBlur={formik.handleBlur}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Select a user"
                      error={formik.touched.user && Boolean(formik.errors.user)}
                      helperText={formik.touched.user && formik.errors.user}
                    />
                  )}
                  sx={{ marginBottom: 2 }}
                />
                {/* 
                  
                 <Autocomplete
                  id="company"
                  name="company"
                  disableClearable
                  options={company}
                  getOptionLabel={(option) => option?.name}
                  value={formik?.values?.company}
                  onChange={(_, newValue) => {
                    formik.setFieldValue("company", newValue);
                  }}
                  onBlur={formik.handleBlur}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Select a company"
                      error={
                        formik.touched.company && Boolean(formik.errors.company)
                      }
                      helperText={
                        formik.touched.company && formik.errors.company
                      }
                    />
                  )}
                  sx={{ marginBottom: 2 }}
                />
                */}

                <Autocomplete
                  // multiple
                  id="teams"
                  name="teams"
                  disableClearable
                  options={team}
                  getOptionLabel={(option) => option?.name}
                  value={formik.values.teams}
                  onChange={(_, newValue) => {
                    formik.setFieldValue("teams", newValue);
                  }}
                  onBlur={formik.handleBlur}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Select teams"
                      error={
                        formik.touched.teams && Boolean(formik.errors.teams)
                      }
                      helperText={formik.touched.teams && formik.errors.teams}
                    />
                  )}
                  sx={{ marginBottom: 2 }}
                />
              </div>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mt: "60px",
              }}
            >
              <Button
                onClick={() => {
                  setIsDrawerOpen(false);
                  formik.resetForm();
                  setDefaultValues({});
                }}
                variant="outlined"
              >
                Cancel
              </Button>
              <Button
                disabled={isButtonDisabled}
                variant="contained"
                type="submit"
              >
                {defaultValuesLength ? "Update" : "Submit"}
              </Button>
            </Box>
          </form>
        </div>
      </Box>
    </Drawer>
  );
};

export default VerticalDrawer;
