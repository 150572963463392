// import React, { useEffect, useState } from "react";
// import AgGridTable from "../../../component/agGrid";
// import Loader from "../../../component/Loader";
// import { Box, IconButton } from "@mui/material";
// import AddIcon from "@mui/icons-material/Add";
// import EditIcon from "@mui/icons-material/Edit";
// import DeleteIcon from "@mui/icons-material/Delete";
// import CompanyDrawer from "./Drawer";
// import SearchAndAddBar from "../../../component/SearchAndAddBar";
// import { deleteCompany, getCompany } from "../../../services";

// const Table = () => {
//   const [rowData, setRowData] = useState([]);
//   const [columnDefs, setColumnDefs] = useState([]);
//   const [isLoading, setIsLoading] = useState(false);
//   const [searchTerm, setSearchTerm] = useState("");
//   const [isDrawerOpen, setIsDrawerOpen] = useState(false);
//   const [defaultValues, setDefaultValues] = useState({});

//   const fetchData = async () => {
//     setIsLoading(true);
//     try {
//       const response = await getCompany();
//       const data = response.data.data.companies;
//       console.log(data, "response.........");

//       const transformedData = data.map(
//         ({ createdBy, __v, updatedAt, createdAt, currency, ...rest }) => {
//           let currencyInfo = "N/A";
//           let currencyId = null;
//           let _id = null;
//           if (currency) {
//             currencyInfo = `${currency.symbol}-${currency.code}`;
//             currencyId = currency._id;
//           }

//           return {
//             ...rest,
//             createdBy: createdBy.name,
//             createdAt: createdAt.split("T")[0],
//             currency: currencyInfo,
//             currencyId: currencyId,
//           };
//         }
//       );

//       const columns = [
//         {
//           headerName: "S.No",
//           valueGetter: "node.rowIndex + 1",
//           sortable: false,
//           filter: false,
//         },
//         ...Object.keys(transformedData[0])
//           .map((key) => {
//             if (key === "currencyId") return null;
//             if (key === "_id") return null;
//             if (key === "isActive") return null;
//             // Exclude currencyId from the columns

//             // Exclude currencyId from the columns
//             return { field: key };
//           })
//           .filter(Boolean),
//         {
//           headerName: "Actions",
//           field: "actions",
//           cellRenderer: (params) => (
//             <div>
//               <IconButton
//                 aria-label="edit"
//                 onClick={() => handleEdit(params.data)}
//                 size="small"
//                 style={{ color: "green" }}
//               >
//                 <EditIcon fontSize="small" />
//               </IconButton>
//               <IconButton
//                 aria-label="delete"
//                 onClick={() => handleDelete(params.data)}
//                 size="small"
//               >
//                 <DeleteIcon color="error" fontSize="small" />
//               </IconButton>
//             </div>
//           ),
//         },
//       ];
//       console.log(transformedData, "transformedData..........");
//       setRowData(transformedData);
//       setColumnDefs(columns);
//     } catch (error) {
//       console.error("Error fetching company data: ", error);
//     } finally {
//       setIsLoading(false);
//     }
//   };
//   useEffect(() => {
//     fetchData();
//   }, []);

//   const handleSearch = (e) => {
//     setSearchTerm(e.target.value);
//   };

//   const filteredRows = rowData.filter((row) =>
//     Object.values(row).some(
//       (value) =>
//         typeof value === "string" &&
//         value.toLowerCase().includes(searchTerm.toLowerCase())
//     )
//   );
//   console.log(rowData, "rowdata.......");
//   console.log(filteredRows, "filteredRows............");
//   const openDrawer = () => {
//     setIsDrawerOpen(true);
//   };

//   const handleEdit = (data) => {
//     console.log("Edit data:", data);
//     setDefaultValues(data);
//     setIsDrawerOpen(true);
//   };

//   const handleDelete = async (data) => {
//     console.log("Delete data:", data._id);
//     await deleteCompany(data._id);
//     await fetchData();
//     // Implement delete functionality here
//   };

//   const handleDrawerClose = () => {
//     setIsDrawerOpen(false);
//     setDefaultValues({});
//     fetchData(); // Fetch data after closing the drawer to get updated list
//   };

//   return (
//     <div>
//       <SearchAndAddBar
//         searchTerm={searchTerm}
//         handleSearch={handleSearch}
//         openDrawer={openDrawer}
//         title={"Company"}
//       />{" "}
//       {isLoading ? (
//         <Loader height="650px" />
//       ) : (
//         <AgGridTable rowData={filteredRows} columnDefs={columnDefs} />
//       )}
//       <CompanyDrawer
//         isDrawerOpen={isDrawerOpen}
//         setIsDrawerOpen={setIsDrawerOpen}
//         defaultValues={defaultValues}
//         setDefaultValues={setDefaultValues}
//         getDataRender={handleDrawerClose}
//       />
//     </div>
//   );
// };

// export default Table;
import React, { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  TextField,
  Button,
  Typography,
  TablePagination,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CompanyDrawer from "./Drawer";
import { deleteCompany, getCompany } from "../../../services";

const Table = () => {
  const [rowData, setRowData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [defaultValues, setDefaultValues] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await getCompany();
      const data = response.data.data.companies;
      setRowData(data.reverse());
    } catch (error) {
      console.error("Error fetching company data: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredRows = rowData.filter((row) =>
    Object.values(row).some(
      (value) =>
        typeof value === "string" &&
        value.toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  // Pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedRows = filteredRows.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const openDrawer = () => {
    setIsDrawerOpen(true);
  };

  const handleEdit = (data) => {
    console.log("Edit data:", data);
    setDefaultValues(data);
    setIsDrawerOpen(true);
  };

  const handleDelete = async (data) => {
    console.log("Delete data:", data._id);
    await deleteCompany(data._id);
    await fetchData();
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
    setDefaultValues({});
    fetchData();
  };

  return (
    <Box>
      <Box display="flex" alignItems="center" mb={2}>
        <TextField
          label="Search"
          variant="outlined"
          value={searchTerm}
          onChange={handleSearch}
        />
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={openDrawer}
          style={{ marginLeft: "auto" }}
        >
          Add Company
        </Button>
      </Box>
      {isLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="650px"
        >
          <CircularProgress />
        </Box>
      ) : filteredRows.length === 0 ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="650px"
        >
          <Typography variant="h6">No Data Found</Typography>
        </Box>
      ) : (
        <Box>
          <TableContainer component={Paper}>
            <MuiTable>
              <TableHead>
                <TableRow>
                  <TableCell>S.No</TableCell>
                  <TableCell>Company Name</TableCell>
                  <TableCell>Created By</TableCell>
                  <TableCell>Created At</TableCell>
                  <TableCell>Currency</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedRows.map((row, index) => (
                  <TableRow key={row._id}>
                    <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.createdBy.name}</TableCell>
                    <TableCell>
                      {new Date(row.createdAt).toLocaleDateString()}
                    </TableCell>
                    <TableCell>
                      {row.currency
                        ? `${row.currency.symbol}-${row.currency.code}`
                        : "N/A"}
                    </TableCell>
                    <TableCell>
                      <IconButton
                        aria-label="edit"
                        onClick={() => handleEdit(row)}
                        size="small"
                        style={{ color: "green" }}
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                      <IconButton
                        aria-label="delete"
                        onClick={() => handleDelete(row)}
                        size="small"
                      >
                        <DeleteIcon color="error" fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </MuiTable>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5,10, 25, 50]}
            component="div"
            count={filteredRows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      )}
      <CompanyDrawer
        isDrawerOpen={isDrawerOpen}
        setIsDrawerOpen={setIsDrawerOpen}
        defaultValues={defaultValues}
        setDefaultValues={setDefaultValues}
        getDataRender={handleDrawerClose}
      />
    </Box>
  );
};

export default Table;
