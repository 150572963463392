import {
  Autocomplete,
  Box,
  Button,
  Drawer,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useFormik, FieldArray, FormikProvider } from "formik";
import * as Yup from "yup";
import {
  addApi,
  addCost,
  getApi,
  getCompany,
  getSource,
  getVerticals,
  updateApi,
  updateCost,
} from "../../services"; // Import getCompany, getVertical similarly
import DeleteIcon from "@mui/icons-material/Delete";
import { enqueueSnackbar } from "notistack";

const validationSchema = Yup.object().shape({
  sourceName: Yup.object().required("Source Name is required"),
  company: Yup.object().required("Company is required"),
  vertical: Yup.object().required("Vertical is required"),
  start_date: Yup.date().required("Start Date is required"),
  // end_date: Yup.date().required("End Date is required"),
  end_date: Yup.date()
    .required("End Date is required")
    .min(Yup.ref("start_date"), "End Date must be later than Start Date"),
  leads: Yup.array()
    .of(
      Yup.object().shape({
        number: Yup.number().required("Number is required"),
        amount: Yup.number().required("Amount is required"),
      })
    )
    .min(1, "At least one lead is required"),
  apis: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.string().required("API ID is required"),
        range: Yup.array()
          .of(Yup.date().required("Date is required"))
          .length(2, "Range must have 2 dates"),
      })
    )
    .min(1, "At least one API is required"),
});

const SourceDrawer = ({
  isDrawerOpen,
  setDefaultValues,
  setIsDrawerOpen,
  defaultValues,
  getDataRender,
}) => {
  const defaultValuesLength =
    defaultValues && Object.keys(defaultValues).length;

  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [source, setSource] = useState([]);
  const [company, setCompany] = useState([]); // State for company options
  const [vertical, setVertical] = useState([]); // State for vertical options
  const [apis, setApis] = useState([]);

  useEffect(() => {
    if (defaultValues !== undefined && Object.keys(defaultValues).length) {
      setFieldValues(defaultValues);
    } else {
      formik.resetForm();
    }
  }, [defaultValues]);

  const getallSource = async () => {
    const { data } = await getSource();
    if (data?.data?.sources) {
      setSource(data?.data?.sources);
    }
  };
  const getAllcompany = async () => {
    const { data } = await getCompany();
    console.log(data, "nnnnnnnnnnnnnnnnnnn");
    if (data?.data?.companies) {
      setCompany(data?.data?.companies);
    }
  };
  const getAllVerticals = async () => {
    const { data } = await getVerticals();
    console.log(data?.data, "nnnnnnnnnnnnnnnnnnn");
    if (data?.data?.verticals) {
      setVertical(data?.data?.verticals);
    }
  };
  const getAllApi = async () => {
    const { data } = await getApi();
    console.log(data?.data, "nnnnnnnnnnnnnnnnnnn");
    if (data?.data?.apis) {
      setApis(data?.data?.apis);
    }
  };
  useEffect(() => {
    getallSource();
    getAllcompany();
    getAllVerticals();
    getAllApi();
  }, []);
  const setFieldValues = (values) => {
    console.log(values);
     const formatDate = (dateString) => {
       if (!dateString) return "";
       const date = new Date(dateString);
       return date.toISOString().split("T")[0]
     };

    console.log(apis, "apis.....");
      const transformedApis = values.apis.map((api) => ({
        id: api.id._id,
        range: api.range.map((date) => formatDate(date)),
      }));
      console.log(transformedApis);
    const selectedSource = source.find(
      (cur) => cur._id === values?.source?._id
    );
    const selectedCompany = company.find(
      (cur) => cur._id === values?.company?._id
    );
    const selectedVertical = vertical.find(
      (cur) => cur._id === values?.vertical?._id
    );

    formik.setFieldValue("sourceName", selectedSource || null);
    formik.setFieldValue("company", selectedCompany || null);
    formik.setFieldValue("vertical", selectedVertical || null);
    formik.setFieldValue("start_date", formatDate(values.start_date) || "");
    formik.setFieldValue("end_date", formatDate(values.end_date) || "");
    formik.setFieldValue("leads", values.leads || [{ number: "", amount: "" }]);
    formik.setFieldValue(
      "apis",
      transformedApis || [{ id: "", range: ["", ""] }]
    );

    setTimeout(() => {
      formik.validateField("sourceName");
      formik.validateField("company");
      formik.validateField("vertical");
      formik.validateField("start_date");
      formik.validateField("end_date");
      formik.validateField("leads");
      formik.validateField("apis");
    }, 0);
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      sourceName: null,
      priceType: null,
      currency: "",
      amount: "",
      urlEndPonit: "",
      vertical: null,
      company: null,
      start_date: "",
      end_date: "",
      leads: [{ number: "", amount: "" }],
      apis: [{ id: "", range: ["", ""] }],
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log(values);
      const validateApiRanges = (apis) => {
        return apis.every((api) => {
          if (api.range && api.range.length === 2) {
            const startDate = new Date(api.range[0]);
            const endDate = new Date(api.range[1]);
            return endDate >= startDate;
          }
          return true;
        });
      };

      const isValidApiRanges = validateApiRanges(values.apis);

      if (!isValidApiRanges) {
        enqueueSnackbar(
          `${"One or more API date ranges are invalid. End Date must be later than Start Date."}`,
          {
            variant: "error",
          }
        );

        return;
      }

      const data = {
        source: values.sourceName?._id,
        company: values.company?._id,
        vertical: values.vertical?._id,
        start_date: values.start_date,
        end_date: values.end_date,
        leads: values.leads,
        apis: values.apis.map((api) => ({
          id: api.id,
          range: [
            new Date(api.range[0]).toISOString(),
            new Date(api.range[1]).toISOString(),
          ],
        })),
      };

      try {
        setButtonDisabled(true);
        if (defaultValuesLength === 0) {
          const response = await addCost(data);
          if (response) {
            setIsDrawerOpen(false);
            formik.resetForm();
            setDefaultValues({});
            getDataRender();
          }
        } else {
          const response = await updateCost(data, defaultValues._id);
          if (response) {
            setIsDrawerOpen(false);
            formik.resetForm();
            setDefaultValues({});
            getDataRender();
          }
        }
      } catch (error) {
        console.error("Error submitting vertical data: ", error);
        enqueueSnackbar(`${error}`, {
          variant: "error",
        });
      } finally {
        setButtonDisabled(false);
      }
    },
  });
  console.log(formik.values.apis, "jjjjjjjjjjjjjjjjjjjjjjj");

  return (
    <Drawer anchor="right" open={isDrawerOpen}>
      <Box
        sx={{ m: 1, width: "20vw", margin: "24px" }}
        noValidate
        autoComplete="off"
      >
        <div>
          <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit}>
              <Typography
                sx={{
                  fontWeight: "600",
                  fontSize: "1.3rem",
                  mt: 4,
                }}
              >
                {defaultValuesLength ? "Edit" : "Add"} Cost
              </Typography>
              <Box>
                <div style={{ width: "330px" }}>
                  <Autocomplete
                    id="sourceName"
                    name="sourceName"
                    disableClearable
                    options={source}
                    getOptionLabel={(option) => option?.name}
                    value={formik?.values?.sourceName}
                    onChange={(_, newValue) => {
                      formik.setFieldValue("sourceName", newValue);
                    }}
                    onBlur={formik.handleBlur}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        label="Select a sourceName"
                        error={
                          formik.touched.sourceName &&
                          Boolean(formik.errors.sourceName)
                        }
                        helperText={
                          formik.touched.sourceName && formik.errors.sourceName
                        }
                      />
                    )}
                    sx={{ marginBottom: 2 }}
                  />
                  <Autocomplete
                    id="company"
                    name="company"
                    disableClearable
                    options={company}
                    getOptionLabel={(option) => option?.name}
                    value={formik?.values?.company}
                    onChange={(_, newValue) => {
                      formik.setFieldValue("company", newValue);
                    }}
                    onBlur={formik.handleBlur}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        label="Select a company"
                        error={
                          formik.touched.company &&
                          Boolean(formik.errors.company)
                        }
                        helperText={
                          formik.touched.company && formik.errors.company
                        }
                      />
                    )}
                    sx={{ marginBottom: 2 }}
                  />
                  <Autocomplete
                    id="vertical"
                    name="vertical"
                    disableClearable
                    options={vertical}
                    getOptionLabel={(option) => option?.name}
                    value={formik?.values?.vertical}
                    onChange={(_, newValue) => {
                      formik.setFieldValue("vertical", newValue);
                    }}
                    onBlur={formik.handleBlur}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        label="Select a vertical"
                        error={
                          formik.touched.vertical &&
                          Boolean(formik.errors.vertical)
                        }
                        helperText={
                          formik.touched.vertical && formik.errors.vertical
                        }
                      />
                    )}
                    sx={{ marginBottom: 2 }}
                  />
                  <Box sx={{ display: "flex", gap: 2 }}>
                    <TextField
                      id="start_date"
                      name="start_date"
                      label="Start Date"
                      type="date"
                      value={formik?.values?.start_date}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.start_date &&
                        Boolean(formik.errors.start_date)
                      }
                      helperText={
                        formik.touched.start_date && formik.errors.start_date
                      }
                      sx={{ marginBottom: 2 }}
                      InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                      id="end_date"
                      name="end_date"
                      label="End Date"
                      type="date"
                      value={formik?.values?.end_date}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.end_date &&
                        Boolean(formik.errors.end_date)
                      }
                      helperText={
                        formik.touched.end_date && formik.errors.end_date
                      }
                      sx={{ marginBottom: 2 }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Box>
                  <Box sx={{ mt: 2, border: "1px solid grey", p: 1 }}>
                    <FieldArray name="leads">
                      {({ remove, push }) => (
                        <div>
                          {formik.values.leads.map((lead, index) => (
                            <div key={index}>
                              <TextField
                                name={`leads[${index}].number`}
                                label="Lead Number"
                                type="number"
                                value={lead.number}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={
                                  formik.touched.leads &&
                                  formik.touched.leads[index] &&
                                  Boolean(formik.errors.leads) &&
                                  Boolean(formik.errors.leads[index]?.number)
                                }
                                helperText={
                                  formik.touched.leads &&
                                  formik.touched.leads[index] &&
                                  formik.errors.leads &&
                                  formik.errors.leads[index]?.number
                                }
                                sx={{ marginBottom: 2, marginRight: 2 }}
                              />
                              <TextField
                                name={`leads[${index}].amount`}
                                label="Lead Amount"
                                type="number"
                                value={lead.amount}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={
                                  formik.touched.leads &&
                                  formik.touched.leads[index] &&
                                  Boolean(formik.errors.leads) &&
                                  Boolean(formik.errors.leads[index]?.amount)
                                }
                                helperText={
                                  formik.touched.leads &&
                                  formik.touched.leads[index] &&
                                  formik.errors.leads &&
                                  formik.errors.leads[index]?.amount
                                }
                                sx={{ marginBottom: 2, marginRight: 2 }}
                              />
                              <IconButton
                                onClick={() => remove(index)}
                                disabled={formik.values.leads.length === 1}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </div>
                          ))}
                          <Button
                            type="button"
                            variant="outlined"
                            onClick={() => push({ number: "", amount: "" })}
                          >
                            Add Lead
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                  </Box>
                  <Box sx={{ mt: 2, border: "1px solid grey", p: 1 }}>
                    {console.log(apis)}
                    <FieldArray name="apis">
                      {({ remove, push }) => (
                        <div>
                          {formik.values.apis.map((api, index) => (
                            <div key={index} style={{ marginBottom: "16px" }}>
                              {/* <TextField
                                name={`apis[${index}].id`}
                                label="API ID"
                                value={api.id}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={
                                  formik.touched.apis &&
                                  formik.touched.apis[index] &&
                                  Boolean(formik.errors.apis) &&
                                  Boolean(formik.errors.apis[index]?.id)
                                }
                                helperText={
                                  formik.touched.apis &&
                                  formik.touched.apis[index] &&
                                  formik.errors.apis &&
                                  formik.errors.apis[index]?.id
                                }
                                sx={{ marginRight: 2, marginBottom: 2 }}
                              /> */}
                              {/* <Autocomplete
                                id={`apis[${index}].id`}
                                name={`apis[${index}].id`}
                                disableClearable
                                options={api}
                                getOptionLabel={(option) => option?.name || null}
                                value={
                                  formik.values.apis[index].id
                                    ? { id: formik.values.apis[index].id }
                                    : null
                                }
                                onChange={(_, newValue) => {
                                  formik.setFieldValue(
                                    `apis[${index}].id`,
                                    newValue?.id || ""
                                  );
                                }}
                                onBlur={formik.handleBlur}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="standard"
                                    label="API ID"
                                    error={
                                      formik.touched.apis &&
                                      formik.touched.apis[index] &&
                                      Boolean(formik.errors.apis) &&
                                      Boolean(formik.errors.apis[index]?.id)
                                    }
                                    helperText={
                                      formik.touched.apis &&
                                      formik.touched.apis[index] &&
                                      formik.errors.apis &&
                                      formik.errors.apis[index]?.id
                                    }
                                  />
                                )}
                                sx={{ marginRight: 2, marginBottom: 2 }}
                              /> */}
                              {/* <Autocomplete
                                id="apiId"
                                name="apiId"
                                disableClearable
                                options={apis}
                                getOptionLabel={(option) => option.name} // or another field if you want
                                value={
                                  formik?.values?.apis?.find(
                                    (api) => api.id === formik.values.apiId
                                  ) || null
                                }
                                onChange={(_, newValue) => {
                                  formik.setFieldValue(
                                    "apiId",
                                    newValue?._id || ""
                                  ); // Adjust based on what field you need
                                }}
                                onBlur={formik.handleBlur}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="standard"
                                    label="Select an API"
                                    error={
                                      formik.touched.apiId &&
                                      Boolean(formik.errors.apiId)
                                    }
                                    helperText={
                                      formik.touched.apiId &&
                                      formik.errors.apiId
                                    }
                                  />
                                )}
                                sx={{ marginBottom: 2 }}
                              /> */}
                              <Autocomplete
                                id={`apis[${index}].id`}
                                name={`apis[${index}].id`}
                                disableClearable
                                options={apis}
                                getOptionLabel={(option) => option.name} // Adjust according to your data structure
                                value={
                                  apis.find(
                                    (api) =>
                                      api._id === formik.values.apis[index]?.id
                                  ) || null
                                }
                                onChange={(_, newValue) => {
                                  formik.setFieldValue(
                                    `apis[${index}].id`,
                                    newValue?._id || ""
                                  ); // Store the full object or ID as needed
                                }}
                                onBlur={formik.handleBlur}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="standard"
                                    label="Select an API"
                                    error={
                                      formik.touched.apis &&
                                      formik.touched.apis[index] &&
                                      Boolean(formik.errors.apis) &&
                                      Boolean(formik.errors.apis[index]?.id)
                                    }
                                    helperText={
                                      formik.touched.apis &&
                                      formik.touched.apis[index] &&
                                      formik.errors.apis &&
                                      formik.errors.apis[index]?.id
                                    }
                                  />
                                )}
                                sx={{ marginBottom: 2 }}
                              />
                              {console.log("Formik values:", formik.values)}
                              {console.log(
                                "Selected API:",
                                apis.find(
                                  (api) =>
                                    api._id === formik.values.apis[index]?.id
                                )
                              )}
                              {/* <Autocomplete
                                id="apiId"
                                name="apiId"
                                disableClearable
                                options={apis}
                                getOptionLabel={(option) => option.name}
                                value={
                                  formik?.values?.apis?.find(
                                    (api) => api.id === formik.values.apiId?.id
                                  ) || null
                                }
                                onChange={(_, newValue) => {
                                  formik.setFieldValue(
                                    "apis",
                                    formik.values.apis.map((api) => ({
                                      ...api,
                                      id: newValue ? newValue._id : "",
                                    }))
                                  );
                                }}
                                onBlur={formik.handleBlur}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="standard"
                                    label="Select an API"
                                    error={
                                      formik.touched.apiId &&
                                      Boolean(formik.errors.apiId)
                                    }
                                    helperText={
                                      formik.touched.apiId &&
                                      formik.errors.apiId
                                    }
                                  />
                                )}
                                sx={{ marginBottom: 2 }}
                              /> */}
                              <Box sx={{ display: "flex", gap: 2 }}>
                                <TextField
                                  name={`apis[${index}].range[0]`}
                                  label="Start Date"
                                  type="date"
                                  value={api.range[0]}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  error={
                                    formik.touched.apis &&
                                    formik.touched.apis[index] &&
                                    Boolean(formik.errors.apis) &&
                                    Boolean(formik.errors.apis[index]?.range) &&
                                    Boolean(formik.errors.apis[index].range[0])
                                  }
                                  helperText={
                                    formik.touched.apis &&
                                    formik.touched.apis[index] &&
                                    formik.errors.apis &&
                                    formik.errors.apis[index]?.range &&
                                    formik.errors.apis[index].range[0]
                                  }
                                  sx={{ flex: 1 }}
                                  InputLabelProps={{ shrink: true }}
                                />
                                <TextField
                                  name={`apis[${index}].range[1]`}
                                  label="End Date"
                                  type="date"
                                  value={api.range[1]}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  error={
                                    formik.touched.apis &&
                                    formik.touched.apis[index] &&
                                    Boolean(formik.errors.apis) &&
                                    Boolean(formik.errors.apis[index]?.range) &&
                                    Boolean(formik.errors.apis[index].range[1])
                                  }
                                  helperText={
                                    formik.touched.apis &&
                                    formik.touched.apis[index] &&
                                    formik.errors.apis &&
                                    formik.errors.apis[index]?.range &&
                                    formik.errors.apis[index].range[1]
                                  }
                                  sx={{ flex: 1 }}
                                  InputLabelProps={{ shrink: true }}
                                />
                              </Box>
                              <IconButton
                                onClick={() => remove(index)}
                                disabled={formik.values.apis.length === 1}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </div>
                          ))}
                          <Button
                            type="button"
                            variant="outlined"
                            onClick={() => push({ id: "", range: ["", ""] })}
                          >
                            Add API
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                  </Box>
                </div>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mt: "60px",
                }}
              >
                <Button
                  onClick={() => {
                    setIsDrawerOpen(false);
                    formik.resetForm();
                    setDefaultValues({});
                  }}
                  variant="outlined"
                >
                  Cancel
                </Button>
                <Button
                  disabled={isButtonDisabled}
                  variant="contained"
                  type="submit"
                >
                  {defaultValuesLength ? "Update" : "Submit"}
                </Button>
              </Box>
            </form>
          </FormikProvider>
        </div>
      </Box>
    </Drawer>
  );
};

export default SourceDrawer;
