import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";
import SideBar from "./sideBar/index";
import Header from "./Header";
// import Header from "./Header";
const MainLayout = () => {
  return (
    <Box>
      {/* <Header /> */}
      <SideBar />
      <Box
        sx={{
          marginLeft: { xs: 0, md: "200px" },
          marginTop: "80px",
          padding: { xs: "5px 10px", sm: "5px 15px", md: "5px 30px" },
          overflow: "auto",
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};
export default MainLayout;
