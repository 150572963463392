// import React, { useEffect } from "react";
// import { useNavigate, useSearchParams } from "react-router-dom";
// import { callBackURL } from "../../../configs/envConfig";
// import { getUsersToken } from "../../../services/core";
// import { getToken, setToken } from "../../../services/jwtService";
// // import { setAllUsers } from "../../../redux/actions";
// import { useDispatch } from "react-redux";
// import { addUser } from "../../../services";
// import Loader from "../../../component/Loader";
// const CallbackHandler = () => {
//   console.log();
//   const [searchParams] = useSearchParams();
//   const navigate = useNavigate();

//   const code = searchParams.get("code");
//   console.log(code, "codfe .......");
//   const handleCore = async () => {
//     debugger;
//     const data = {
//       data: {
//         redirectUri: "https://dev.art.visuallabs.io/callback",
//         clientName: "artemis",
//         code: code,
//       },
//     };
//     console.log("data", data);
//     debugger;
//     const res = await getToken(data);

//     if (res?.data?.status?.code === 200) {
//       setToken(res?.data?.data);

//       const userRes = await getUsersToken(res?.data?.data.token.access_token);
//       const addUserRes = await addUser();
//       // dispatch(setAllUsers(userRes?.data?.data));
//       console.log("addUserRes", addUserRes);
//       return navigate("/");
//     }
//   };

//   useEffect(() => {
//     handleCore();
//   });

//   // useEffect(() => {
//   //   if(JSON.parse(localStorage.getItem("user"))?.roles[0] === "admin"){
//   //     navigate("/answer-board")
//   //   }
//   // }, [])

//   return (
//     <div>
//       <Loader />
//     </div>
//   );
// };

// export default CallbackHandler;

import { Box } from "@mui/system";
import axios from "axios";
import { baseURL, callBackURL, gatewayURL } from "../../../configs/envConfig";
import React from "react";
import { useEffect } from "react";
// import "./login.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import { addUser } from "../../../services";
import { ROLE_ADMIN, ROLE_SUPER_ADMIN, ROLE_USER } from "../../../constant";
const CallbackHandlerPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");
  const handlerCore = async () => {
    console.log(callBackURL);
    const data = {
      data: {
        redirectUri: callBackURL,
        clientName: "artemis",
        code: code,
      },
    };
    await axios.post(`${gatewayURL}/core/get-token`, data).then((response) => {
      const rdata = response.data;
      if (rdata.status.code === 200) {
        localStorage.setItem(
          "token",
          JSON.stringify(response?.data?.data?.token)
        );
        localStorage.setItem(
          "userInfo",
          JSON.stringify(response?.data?.data?.user)
        );
        if (response.data.data.user.roles[0] === "Super Admin") {
          addUser();
        }
        if (response.data.data.user.roles[0] === ROLE_SUPER_ADMIN) {
          return navigate("/preset/company-name");
        }
        if (response.data.data.user.roles[0] === ROLE_ADMIN) {
          return navigate("/cost");
        }
        if (response.data.data.user.roles[0] === ROLE_USER) {
          return navigate("/user");
        }
      }
    });
  };
  useEffect(() => {
    handlerCore();
  }, []);
  return (
    <>
      <Box
        sx={{
          height: "100vh",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div class="spinner"></div>
      </Box>
    </>
  );
};
export default CallbackHandlerPage;
