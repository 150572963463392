import { ListItem, ListItemIcon, ListItemText, Tooltip } from "@mui/material";

const SidebarItem = ({ title, icon, onClick, selected, disabled }) => {
  return (
    <Tooltip title={title}>
      <ListItem
        disabled={disabled}
        onClick={onClick}
        sx={{
          my: 2,
          cursor: "pointer",
          height: "64.456px",
          borderRadius: "15px",
          padding: "5px",
          justifyContent: "center",
          display: disabled ? "none" : "flex",
          alignItems: "center",
          ":hover": {
            backgroundColor: "#0C247B",
            color: "#FFFFFF",
            "& .MuiListItemIcon-root, & .MuiListItemText-root": {
              color: "#FFFFFF",
            },
          },
          ...(selected && {
            backgroundColor: "#0C247B",
            color: "#FFFFFF",
          }),
        }}
      >
        <ListItemIcon
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            ":hover": {
              backgroundColor: "#0C247B",
              color: "#FFFFFF",
            },
            ...(selected && {
              backgroundColor: "#0C247B",
              color: "#FFFFFF",
            }),
          }}
        >
          {icon}
        </ListItemIcon>
        <ListItemText primary={title} />
      </ListItem>
    </Tooltip>
  );
};
export default SidebarItem