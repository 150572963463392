import React from "react";
import Table from "./Table";
import PresetHeader from "../../layouts/MainLayout/Header/presetHeader";

const Index = () => {
  return (
    <div>
      {/* <PresetHeader /> */}
      <Table />
    </div>
  );
};

export default Index;
