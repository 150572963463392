// src/components/SideBar.js
import React from "react";
import { Box, Divider, Drawer, List } from "@mui/material";
// import { useLocation, useNavigate } from "react-router-dom";
import LoginOutlinedIcon from "@mui/icons-material/LoginOutlined";
import TuneIcon from "@mui/icons-material/Tune";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import { ROLE_USER, ROLE_ADMIN } from "../../../constant/index";
import { useNavigate } from "react-router-dom";
import SidebarItem from "./SidebarItem";
const token = JSON.parse(localStorage.getItem("userInfo"));

const SideBar = () => {
  const navigate = useNavigate();
  // const location = useLocation();
  const role = token?.roles[0];
  console.log(role);
  console.log(ROLE_USER);
  const isItemSelected = (path) => window.location.pathname.includes(path);

  const sidebarItems = [
    {
      title: "Preset",
      icon: <TuneIcon fontSize="large" />,
      onClick: () => {
        if (role !== ROLE_USER) {
          navigate("/preset/company-name");
        }
      },
      selected: isItemSelected("/preset"),
      disabled: role === ROLE_USER,
    },
    {
      title: "User",
      icon: <SupervisedUserCircleIcon fontSize="large" />,
      onClick: () => {
        navigate("/user");
      },
      selected: isItemSelected("/user"),
      disabled: role === ROLE_USER || role === ROLE_ADMIN,
    },
    {
      title: "Cost Management",
      icon: <MonetizationOnIcon fontSize="large" />,
      onClick: () => {
        navigate("/cost");
      },
      selected: isItemSelected("/cost"),
      // disabled: role === ROLE_USER || role === ROLE_ADMIN,
    },
    {
      title: "Logout",
      icon: <LoginOutlinedIcon fontSize="large" />,
      onClick: () => {
        navigate("/logout");
        localStorage.clear();
      },
    },
  ];

  return (
    <Box>
      <Drawer
        id="sidebar"
        variant="permanent"
        anchor="left"
        open
        sx={{
          bgcolor: "black",
          transition: "all 1s ease",
          display: { xs: "block", md: "block" },
          position: "absolute",
          zIndex: 1200,
          [`& .MuiDrawer-paper`]: {
            top: 0,
            maxHeight: "100vh",
            width: {
              xs: "50%",
              sm: "40%",
              md: "200px",
            },
          },
        }}
      >
        <List
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "#FFFFFF",
            height: "100vh",
            marginTop: "3rem",
            overflowX: "hidden",
            padding: "12px",
          }}
        >
          {sidebarItems?.map((item, index) => (
            <SidebarItem
              key={index}
              title={item?.title}
              icon={item?.icon}
              onClick={item?.onClick}
              selected={item?.selected}
              disabled={item?.disabled}
            />
          ))}
        </List>
      </Drawer>
      <Divider color="blue" />
    </Box>
  );
};

export default SideBar;
