import React, { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  TextField,
  Button,
  Typography,
  TablePagination,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CompanyDrawer from "./Drawer";
import { deleteTeam, deleteVerttical, getTeams, getVerticals } from "../../../services";

const Table = () => {
  const [rowData, setRowData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [defaultValues, setDefaultValues] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await getVerticals();
      console.log(response);
      const data = response.data.data.verticals;
      setRowData(data.reverse());
    } catch (error) {
      console.error("Error fetching company data: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredRows = rowData.filter((row) =>
    Object.values(row).some(
      (value) =>
        typeof value === "string" &&
        value.toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  // Pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedRows = filteredRows.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const openDrawer = () => {
    setIsDrawerOpen(true);
  };

  const handleEdit = (data) => {
    console.log("Edit data:", data);
    setDefaultValues(data);
    setIsDrawerOpen(true);
  };

  const handleDelete = async (data) => {
    console.log("Delete data:", data._id);
    await deleteVerttical(data._id);
    await fetchData();
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
    setDefaultValues({});
    fetchData();
  };

  return (
    <Box>
      <Box display="flex" alignItems="center" mb={2}>
        <TextField
          label="Search"
          variant="outlined"
          value={searchTerm}
          onChange={handleSearch}
        />
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={openDrawer}
          style={{ marginLeft: "auto" }}
        >
          Add Vertical
        </Button>
      </Box>
      {isLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="650px"
        >
          <CircularProgress />
        </Box>
      ) : filteredRows.length === 0 ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="650px"
        >
          <Typography variant="h6">No Data Found</Typography>
        </Box>
      ) : (
        <Box>
          <TableContainer component={Paper}>
            <MuiTable>
              <TableHead>
                <TableRow>
                  <TableCell>S.No</TableCell>
                  <TableCell>Vertical Name</TableCell>
                  <TableCell>Team Name</TableCell>
                  <TableCell>Company</TableCell>
                  <TableCell>Created By</TableCell>
                  <TableCell>Created At</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {console.log(
                  paginatedRows,
                  ":aaaaaaaaaaaaaaaaaaaaaaaaaaalllllllll"
                )}
                {paginatedRows.map((row, index) => (
                  <TableRow key={row._id}>
                    <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row?.teams[0]?.name}</TableCell>
                    <TableCell>{row?.company?.name}</TableCell>

                    <TableCell>{row.createdBy.name}</TableCell>
                    <TableCell>
                      {new Date(row?.createdAt).toLocaleDateString()}
                    </TableCell>
                    <TableCell>
                      <IconButton
                        aria-label="edit"
                        onClick={() => handleEdit(row)}
                        size="small"
                        style={{ color: "green" }}
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                      <IconButton
                        aria-label="delete"
                        onClick={() => handleDelete(row)}
                        size="small"
                      >
                        <DeleteIcon color="error" fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </MuiTable>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={filteredRows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      )}
      <CompanyDrawer
        isDrawerOpen={isDrawerOpen}
        setIsDrawerOpen={setIsDrawerOpen}
        defaultValues={defaultValues}
        setDefaultValues={setDefaultValues}
        getDataRender={handleDrawerClose}
      />
    </Box>
  );
};

export default Table;
