import axios from "axios";
// import { enqueueSnackbar } from "notistack";

const baseURL = process.env.REACT_APP_GATEWAY_URL;
const idpURL = process.env.REACT_APP_IDP_URL;
const logoutURL = process.env.REACT_APP_ARTEMIS_POST_LOGOUT_REDIRECT_URI;

export const setToken = (data) => {
  localStorage.setItem("token", JSON.stringify(data.token));
  if (data?.user) {
    localStorage.setItem("user", JSON.stringify(data.user));
    console.log(data.user, "data.user...........");
  } else {
    const errorMessage =
      "User data is not available. Please contact the administration.";
    console.error(errorMessage);
    // enqueueSnackbar(errorMessage, { variant: 'error' });
    return;
  }
};

export const getUser = () => {
  return localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null;
};

export const getToken = () => {
  const token = localStorage.getItem("token").access_token
    ? JSON.parse(localStorage.getItem("token")).access_token
    : null;
  console.log(token, "..........");
  return token;
};

export const logout = () => {
  const data = {
    clientName: "artemis",
  };
  const headers = {
    Authorization: JSON.parse(localStorage.getItem("token"))?.access_token
      ? "Bearer " + JSON.parse(localStorage.getItem("token"))?.access_token
      : null,
    "Content-Type": "application/json",
    accept: "application/json",
  };
  axios
    .post(
      `${baseURL}/secured/introspect`,
      {
        data,
      },
      { headers }
    )
    .then((result) => {
      if (result.data.data.active) {
        const id_token = JSON.parse(localStorage.getItem("token"))?.id_token;
        window.location.href = `${idpURL}/connect/logout?post_logout_redirect_uri=${logoutURL}&id_token_hint=${id_token}`;
      } else {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        window.location.href = "/login";
      }
    })
    .catch((error) => {
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      window.location.href = "/login";
    });
};
