import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Company from "../views/preset/company/index";
import Vertical from "../views/preset/vertical/index";
import User from "../views/user/Index";
import Teams from "../views/preset/Teams/index";
import Logout from "../views/Authentication/Logout/Logout";
import Login from "../views/Authentication/Login";
import CallbackHandler from "../views/Authentication/CallbackHandler";
import ProtectedRoute from "./ProtectedRoute";
import UserRoute from "./UserRoute";
import MainLayout from "../layouts/MainLayout/index";
import Source from "../views/preset/Source/index";
import Api from "../views/preset/Api/index";
import Cost from "../views/cost/index";

const RouterComponent = () => {
  return (
    <Router>
      <Routes>
        <Route element={<ProtectedRoute />}>
          <Route element={<UserRoute />}>
            <Route path="*" element={<Navigate to="/login" />} />
          </Route>
        </Route>
        <Route element={<MainLayout />}>
          <Route path="/preset/company-name" element={<Company />} />
          <Route path="/Preset/source" element={<Source />} />
          <Route path="/Preset/teams" element={<Teams />} />
          <Route path="/preset/api" element={<Api />} />
          <Route path="/preset/vertical" element={<Vertical />} />
          <Route path="/user" element={<User />} />
          <Route path="/cost" element={<Cost />} />
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/callback" element={<CallbackHandler />} />
      </Routes>
    </Router>
  );
};

export default RouterComponent;
