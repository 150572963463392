import { Navigate, Outlet } from "react-router-dom";

const UserRoute = () => {
  const role = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))?.roles[0]
    : null;

  return role === "user" ? (
    <>
      <Outlet />
    </>
  ) : (
    <Navigate to="/login" replace />
  );
};

export default UserRoute;
