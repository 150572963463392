// import {
//   Autocomplete,
//   Box,
//   Button,
//   Drawer,
//   TextField,
//   Typography,
// } from "@mui/material";
// import React, { useEffect, useState } from "react";
// import { useFormik } from "formik";
// import * as Yup from "yup";
// import {
//   addCompany,
//   addTeam,
//   addVerttical,
//   getCompany,
//   getTeams,
//   getcompany,
//   updateCompany,
//   updateTeam,
//   updateVerttical,
// } from "../../../services/index";

// const validationSchema = Yup.object().shape({
//   name: Yup.string()
//     .trim()
//     .required("Team Name Required")
//     .matches(/^[^\s]+(\s[^\s]+)*$/, "Only one space is allowed between words"),
//   company: Yup.object().required("company is required"),
// });

// const VerticalDrawer = ({
//   isDrawerOpen,
//   setDefaultValues,
//   setIsDrawerOpen,
//   defaultValues,
//   getDataRender,
// }) => {
//   const defaultValuesLength =
//     defaultValues && Object.keys(defaultValues).length;

//   const [isButtonDisabled, setButtonDisabled] = useState(false);
//   const [company, setcompany] = useState([]);
//   const [team, setTeam] = useState([]);

//   console.log(defaultValues);
//   useEffect(() => {
//     if (defaultValues !== undefined && Object.keys(defaultValues).length) {
//       setFieldValues(defaultValues);
//     } else {
//       formik.resetForm();
//     }
//   }, [defaultValues]);

//   const getAllcompany = async () => {
//     const { data } = await getCompany();
//     console.log(data?.data?.companies, "nnnnnnnnnnnnnnnnnnn");
//     setcompany(data?.data?.companies);
//   };
//   const getAllTeams = async () => {
//     const { data } = await getTeams();
//     console.log(data?.data.teams, "aaaaaaaaaaaaaaaaa");
//     setTeam(data?.data?.teams);
//   };

//   useEffect(() => {
//     getAllcompany();
//     getAllTeams();
//   }, []);

//   const setFieldValues = (values) => {
//     console.log(values, "kkkkkkkkkkkk");
//     formik.setFieldValue("name", values?.name);
//     const selectedcompany = company.find(
//       (cur) => cur._id === values?.company?._id
//     );
//     formik.setFieldValue("company", selectedcompany || null);
//     setTimeout(() => {
//       formik.validateField("name");
//     }, 0);
//   };

//   const formik = useFormik({
//     initialValues: {
//       name: "",
//       company: null,
//       teams: [],
//     },
//     validationSchema: validationSchema,
//     onSubmit: async (values) => {
//       const data = {
//         name: values.name.trim(),
//         company: values.company._id,
//         teams: values.teams,
//       };

//       try {
//         setButtonDisabled(true);
//         // debugger
//         if (defaultValuesLength === 0) {
//           await addVerttical(data);
//         } else {
//           await updateVerttical(data, defaultValues._id);
//         }
//         setIsDrawerOpen(false);
//         formik.resetForm();
//         setDefaultValues({});
//         getDataRender();
//       } catch (error) {
//         console.error("Error submitting company data: ", error);
//       } finally {
//         setButtonDisabled(false);
//       }
//     },
//   });

//   return (
//     <Drawer anchor="right" open={isDrawerOpen}>
//       <Box
//         sx={{ m: 1, width: "20vw", margin: "24px" }}
//         noValidate
//         autoComplete="off"
//       >
//         <div>
//           <form onSubmit={formik.handleSubmit}>
//             <Typography
//               sx={{
//                 fontWeight: "600",
//                 fontSize: "1.3rem",
//                 mt: 4,
//               }}
//             >
//               {defaultValuesLength ? "Edit" : "Add"} Vertical Name
//             </Typography>
//             <Box>
//               <div style={{ width: "330px" }}>
//                 <TextField
//                   fullWidth
//                   variant="standard"
//                   label="Vertical Name"
//                   name="name"
//                   value={formik.values.name}
//                   onChange={formik.handleChange}
//                   onBlur={formik.handleBlur}
//                   error={formik.touched.name && Boolean(formik.errors.name)}
//                   helperText={formik.touched.name && formik.errors.name}
//                   sx={{ marginBottom: 2 }}
//                 />
//                 {console.log(company, "mmmmmmmmmmmmmmmmmmmmmmmm")}
//                 <Autocomplete
//                   id="company"
//                   name="company"
//                   disableClearable
//                   options={company}
//                   getOptionLabel={(option) => option?.name}
//                   value={formik?.values?.company}
//                   onChange={(_, newValue) => {
//                     formik.setFieldValue("company", newValue);
//                   }}
//                   onBlur={formik.handleBlur}
//                   renderInput={(params) => (
//                     <TextField
//                       {...params}
//                       variant="standard"
//                       label="Select a company"
//                       error={
//                         formik.touched.company && Boolean(formik.errors.company)
//                       }
//                       helperText={
//                         formik.touched.company && formik.errors.company
//                       }
//                     />
//                   )}
//                   sx={{ marginBottom: 2 }}
//                 />
//                 <Autocomplete
//                   multiple
//                   id="teams"
//                   name="teams"
//                   disableClearable
//                   options={team}
//                   getOptionLabel={(option) => option?.name}
//                   value={formik?.values?.teams}
//                   onChange={(_, newValue) => {
//                     debugger
//                     formik.setFieldValue("teams", newValue?._id);
//                   }}
//                   onBlur={formik.handleBlur}
//                   renderInput={(params) => (
//                     <TextField
//                       {...params}
//                       variant="standard"
//                       label="Select a teams"
//                       error={
//                         formik.touched.teams && Boolean(formik.errors.teams)
//                       }
//                       helperText={formik.touched.teams && formik.errors.teams}
//                     />
//                   )}
//                   sx={{ marginBottom: 2 }}
//                 />
//               </div>
//             </Box>

//             <Box
//               sx={{
//                 display: "flex",
//                 justifyContent: "space-between",
//                 mt: "60px",
//               }}
//             >
//               <Button
//                 onClick={() => {
//                   setIsDrawerOpen(false);
//                   formik.resetForm();
//                   setDefaultValues({});
//                 }}
//                 variant="outlined"
//               >
//                 Cancel
//               </Button>
//               <Button
//                 disabled={isButtonDisabled}
//                 variant="contained"
//                 type="submit"
//               >
//                 {defaultValuesLength ? "Update" : "Submit"}
//               </Button>
//             </Box>
//           </form>
//         </div>
//       </Box>
//     </Drawer>
//   );
// };

// export default VerticalDrawer;
import {
  Autocomplete,
  Box,
  Button,
  Drawer,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  addCompany,
  addTeam,
  addVerttical,
  getCompany,
  getTeams,
  getcompany,
  updateCompany,
  updateTeam,
  updateVerttical,
} from "../../../services/index";
import { enqueueSnackbar } from "notistack";

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .required("Team Name Required")
    .matches(/^[^\s]+(\s[^\s]+)*$/, "Only one space is allowed between words"),
  company: Yup.object().required("Company is required"),
});

const VerticalDrawer = ({
  isDrawerOpen,
  setDefaultValues,
  setIsDrawerOpen,
  defaultValues,
  getDataRender,
}) => {
  const defaultValuesLength =
    defaultValues && Object.keys(defaultValues).length;

  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [company, setCompany] = useState([]);
  const [team, setTeam] = useState([]);

  useEffect(() => {
    if (defaultValues !== undefined && Object.keys(defaultValues).length) {
      setFieldValues(defaultValues);
    } else {
      formik.resetForm();
    }
  }, [defaultValues]);

  const getAllCompany = async () => {
    const { data } = await getCompany();
    if (data?.data?.companies) {
      setCompany(data?.data?.companies);
    }
  };

  const getAllTeams = async () => {
    const { data } = await getTeams();
    if (data?.data?.teams) {
      setTeam(data?.data?.teams);
    }
  };

  useEffect(() => {
    getAllCompany();
    getAllTeams();
  }, []);

  const setFieldValues = (values) => {
    formik.setFieldValue("name", values?.name);
    const selectedCompany = company.find(
      (cur) => cur._id === values?.company?._id
    );
    formik.setFieldValue("company", selectedCompany || null);
    const selectedTeams = values?.teams.map((team) => team._id);
    formik.setFieldValue("teams", selectedTeams || []);
    setTimeout(() => {
      formik.validateField("name");
    }, 0);
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      company: null,
      teams: [],
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const data = {
        name: values.name.trim(),
        company: values.company._id,
        teams: values.teams,
      };

      try {
        setButtonDisabled(true);
        if (defaultValuesLength === 0) {
          const res = await addVerttical(data);
          if (res) {
            setIsDrawerOpen(false);
            formik.resetForm();
            setDefaultValues({});
            getDataRender();
          }
        } else {
          const res = await updateVerttical(data, defaultValues._id);
          if (res) {
            setIsDrawerOpen(false);
            formik.resetForm();
            setDefaultValues({});
            getDataRender();
          }
        }
      } catch (error) {
        debugger
        console.error("Error submitting vertical data: ", error);
         enqueueSnackbar(`${error?.response?.data?.err}`, {
           variant: "error",
         });
      } finally {
        setButtonDisabled(false);
      }
    },
  });

  return (
    <Drawer anchor="right" open={isDrawerOpen}>
      <Box
        sx={{ m: 1, width: "20vw", margin: "24px" }}
        noValidate
        autoComplete="off"
      >
        <div>
          <form onSubmit={formik.handleSubmit}>
            <Typography
              sx={{
                fontWeight: "600",
                fontSize: "1.3rem",
                mt: 4,
              }}
            >
              {defaultValuesLength ? "Edit" : "Add"} Vertical Name
            </Typography>
            <Box>
              <div style={{ width: "330px" }}>
                <TextField
                  autoComplete="off"
                  fullWidth
                  variant="standard"
                  label="Vertical Name"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                  sx={{ marginBottom: 2 }}
                />
                <Autocomplete
                  id="company"
                  name="company"
                  disableClearable
                  options={company}
                  getOptionLabel={(option) => option?.name}
                  value={formik?.values?.company}
                  onChange={(_, newValue) => {
                    formik.setFieldValue("company", newValue);
                  }}
                  onBlur={formik.handleBlur}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Select a company"
                      error={
                        formik.touched.company && Boolean(formik.errors.company)
                      }
                      helperText={
                        formik.touched.company && formik.errors.company
                      }
                    />
                  )}
                  sx={{ marginBottom: 2 }}
                />
                <Autocomplete
                  multiple
                  id="teams"
                  name="teams"
                  disableClearable
                  options={team}
                  getOptionLabel={(option) => option?.name}
                  value={formik.values.teams.map((teamId) =>
                    team.find((t) => t._id === teamId)
                  )}
                  onChange={(_, newValue) => {
                    const teamIds = newValue.map((team) => team._id);
                    formik.setFieldValue("teams", teamIds);
                  }}
                  onBlur={formik.handleBlur}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Select teams"
                      error={
                        formik.touched.teams && Boolean(formik.errors.teams)
                      }
                      helperText={formik.touched.teams && formik.errors.teams}
                    />
                  )}
                  sx={{ marginBottom: 2 }}
                />
              </div>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mt: "60px",
              }}
            >
              <Button
                onClick={() => {
                  setIsDrawerOpen(false);
                  formik.resetForm();
                  setDefaultValues({});
                }}
                variant="outlined"
              >
                Cancel
              </Button>
              <Button
                disabled={isButtonDisabled}
                variant="contained"
                type="submit"
              >
                {defaultValuesLength ? "Update" : "Submit"}
              </Button>
            </Box>
          </form>
        </div>
      </Box>
    </Drawer>
  );
};

export default VerticalDrawer;
