export const apiEndPointsConfig = {
  //Core
  getToken: "/core/get-token",
  refreshToke: "/core/refresh-token",
  getClients: "/core/get-clients",
  getUsers: "/secured/get-users",

  // company
  getAllCompany: "company/get-companies",
  getcurrency: "currency/get-currencies",
  addCompany: "company/create-company",
  editCompany: "company/",
  deleteCompany: "company/deactivate/",
  // Teams
  getAllTeams: "team/get-teams",
  addTeam: "team/create-team",
  editTeam: "team/",
  deleteTeam: "/team/deactivate/",
  // Vertical
  getAllVerticals: "vertical/get-verticals",
  addVertical: "vertical/create-vertical",
  editVertical: "vertical/",
  deleteVertical: "/vertical/deactivate/",
  // source
  getAllSources: "source/get-sources",
  addsource: "source/create-source",
  editsource: "source/",
  deletesource: "/source/deactivate/",
  // USER
  addUser: "/user/create-User",
  adduserinteam: "/user/user-in-team",
  getAllUser: "user/get-users",
  edituserinteam: "user/",
  deleteuser: "/user/deactivate/",
  // api
  addWebhook: "/webhook/create-webhook",
  addApi: "apis/create-api",
  getApi: "apis/get-apis",
  updateApi: "apis/",
  deleteAPI: "apis/deactivate/",
  // cost
  addCost: "/cost/create-cost",
  getCost: "/cost/get-costs",
  updateCost: "cost/",
};
