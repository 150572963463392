import React from "react";
import PresetHeader from "../../../layouts/MainLayout/Header/presetHeader";
import Table from "./Table";

const Index = () => {
  return (
    <div >
      <PresetHeader />
      <Table />
    </div>
  );
};

export default Index;
