import "./App.css";
import RouterComponent from "./routes";
// import { SnackbarProvider } from "notistack";
import { SnackbarProvider } from "notistack";

function App() {
  return (
    <>
      {/* <SnackbarProvider autoHideDuration={3000} dense action maxSnack={3}> */}
      <SnackbarProvider>
        <RouterComponent />
      </SnackbarProvider>

      {/* </SnackbarProvider> */}
    </>
  );
}

export default App;
