import React, { useState, useEffect } from "react";
import { Box, Button, Card, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ROLE, ROLE_ADMIN, ROLE_SUPER_ADMIN } from "../../../../constant";
const PresetHeader = () => {
  const navigate = useNavigate();
  //   const [activePath, setActivePath] = useState("/preset/company-name");
  const [activePath, setActivePath] = useState(window.location.pathname);

  const handleButtonClick = (path) => {
    setActivePath(path);
    navigate(path);
  };
  const renderButton = (path, label) => {
    return (
      <>
        <Button
          variant={activePath == path ? "contained" : "outlined"}
          onClick={() => handleButtonClick(path)}
        >
          {label}
        </Button>
      </>
    );
  };
  const token = JSON.parse(localStorage.getItem("userInfo"));

  return (
    <Card sx={{ marginBottom: 3 }}>
      <Grid container spacing={2}>
        <Grid item xs={6} md={4} ml={4}>
          <Box
            sx={{
              fontSize: "1.5rem",
              fontWeight: "700",
              // marginTop: "5px",
              marginLeft: "30px",
            }}
          />
          {/* <Box sx={{ marginLeft: '30px' }} /> */}
          {/* <Typography variant="h2" sx={{ mt: "20px", fontFamily: "sans-serif" }}> Presets</Typography> */}
        </Grid>
        <Grid sx={{ marginBottom: 2 }} item xs={6} md={7}>
          <Box display={"flex"} justifyContent={"end"} gap={2}>
            {token?.roles[0] === ROLE_SUPER_ADMIN &&
              renderButton("/preset/company-name", "Company Name")}
            {token?.roles[0] === ROLE_SUPER_ADMIN &&
              renderButton("/preset/teams", "Teams")}
            {token?.roles[0] === ROLE_SUPER_ADMIN &&
              renderButton("/preset/vertical", "Vertical")}
            {(token?.roles[0] === ROLE_SUPER_ADMIN || ROLE_ADMIN) &&
              renderButton("/preset/source", "Source")}
            {(token?.roles[0] === ROLE_SUPER_ADMIN || ROLE_ADMIN) &&
              renderButton("/preset/api", "API")}
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
};
export default PresetHeader;
