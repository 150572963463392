import {
  Autocomplete,
  Box,
  Button,
  Drawer,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  addApi,
  addSource,
  getSource,
  getVerticals,
  updateApi,
  updatesource,
} from "../../../services/index";
import { enqueueSnackbar } from "notistack";

const validationSchema = Yup.object().shape({
  sourceName: Yup.object().required("Source Name is required"),
  name: Yup.string()
    .trim()
    .required(" Name is required")
    .matches(/^[^\s]+(\s[^\s]+)*$/, "Only one space is allowed between words"),

  priceType: Yup.object().required("Price Type is required"),
  currency: Yup.string().required("Currency is required"),
  // amount: Yup.number().required("Amount is required"),
  amount: Yup.number()
    .typeError("Amount must be a number")
    .required("Amount is required")
    .test("is-valid-number", "Invalid number format", (value) => {
      // Check if the value is a number and does not include invalid characters like 'e'
      return !isNaN(value) && !/e/.test(String(value));
    }),
  urlEndPonit: Yup.string().required("URL Endpoint is required"),
});

const SourceDrawer = ({
  isDrawerOpen,
  setDefaultValues,
  setIsDrawerOpen,
  defaultValues,
  getDataRender,
  WebhookData,
}) => {
  console.log(WebhookData);
  const defaultValuesLength =
    defaultValues && Object.keys(defaultValues).length;

  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [source, setSource] = useState([]);

  useEffect(() => {
    if (defaultValues !== undefined && Object.keys(defaultValues).length) {
      setFieldValues(defaultValues);
    } else {
      formik.resetForm();
    }
  }, [defaultValues]);
  const getallSource = async () => {
    const { data } = await getSource();
    if (data?.data?.sources) {
      console.log(data.data);
      setSource(data?.data?.sources);
    }
  };
  useEffect(() => {
    getallSource();
  }, []);
  const AllpriceType = [
    {
      label: "Monthly",
      value: "monthly",
    },
    {
      label: "Per Request",
      value: "per_request",
    },
  ];
  const setFieldValues = (values) => {
    console.log(values);
    //    name: "",
    // sourceName: null,
    // priceType: null,
    // currency: "",
    // amount: "",
    // urlEndPonit: "",
    // vertical: null,
    formik.setFieldValue("name", values?.name);
    formik.setFieldValue("amount", values?.pricing?.amount);
    formik.setFieldValue("urlEndPonit", values?.urlEndPoint);

    const selectedsource = source.find(
      (cur) => cur._id === values?.source?._id
    );
    formik.setFieldValue("sourceName", selectedsource || null);

    const selectedPricetype = AllpriceType.find(
      (cur) => cur.value === values?.pricing?.type
    );
    console.log(selectedPricetype, "selected price ..............");
    formik.setFieldValue("priceType", selectedPricetype || null);
    formik.setFieldValue("sourceName", selectedsource || null);
    formik.setFieldValue("currency", values.pricing.currency);

    setTimeout(() => {
      formik.validateField("sourceName");
    }, 0);
  };
  const formik = useFormik({
    initialValues: {
      name: "",
      sourceName: null,
      priceType: null,
      currency: "",
      amount: "",
      urlEndPonit: "",
      vertical: null,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log(values);
      const data = {
        name: values.name,
        pricing: {
          type: values.priceType.value,
          amount: values.amount,
          currency: values.currency,
        },
        urlEndPoint: WebhookData?.url,
        source: values.sourceName._id,
        webhooks: WebhookData?._id,
      };

      try {
        setButtonDisabled(true);
        if (defaultValuesLength === 0) {
          const response = await addApi(data);
          if (response) {
            setIsDrawerOpen(false);
            formik.resetForm();
            setDefaultValues({});
            getDataRender();
          }
        } else {
          const response = await updateApi(data, defaultValues._id);
          if (response) {
            setIsDrawerOpen(false);
            formik.resetForm();
            setDefaultValues({});
            getDataRender();
          }
        }
      } catch (error) {
        console.error("Error submitting vertical data: ", error);
         enqueueSnackbar(`${error?.response?.data?.err}`, {
           variant: "error",
         });
      } finally {
        setButtonDisabled(false);
      }
    },
  });
  const handleCopyUrl = () => {
    if (WebhookData?.url) {
      navigator.clipboard.writeText(WebhookData.url);
    }
  };
  return (
    <Drawer anchor="right" open={isDrawerOpen}>
      <Box
        sx={{ m: 1, width: "20vw", margin: "24px" }}
        noValidate
        autoComplete="off"
      >
        <div>
          <form onSubmit={formik.handleSubmit}>
            <Typography
              sx={{
                fontWeight: "600",
                fontSize: "1.3rem",
                mt: 4,
              }}
            >
              {defaultValuesLength ? "Edit" : "Add"} Api
            </Typography>
            <Box>
              <div style={{ width: "330px" }}>
                <TextField
                  fullWidth
                  autoComplete="off"
                  variant="standard"
                  label=" Name"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                  sx={{ marginBottom: 2 }}
                />
                {/* <TextField
                  fullWidth
                  autoComplete="off"
                  variant="standard"
                  label="Source Name"
                  name="sourceName"
                  value={formik.values.sourceName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.sourceName &&
                    Boolean(formik.errors.sourceName)
                  }
                  helperText={
                    formik.touched.sourceName && formik.errors.sourceName
                  }
                  sx={{ marginBottom: 2 }}
                /> */}
                {console.log(source, "sooooooooooooooooo")}
                <Autocomplete
                  id="sourceName"
                  name="sourceName"
                  disableClearable
                  options={source}
                  getOptionLabel={(option) => option?.name}
                  value={formik?.values?.sourceName}
                  onChange={(_, newValue) => {
                    formik.setFieldValue("sourceName", newValue);
                  }}
                  onBlur={formik.handleBlur}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Select a sourceName"
                      error={
                        formik.touched.sourceName &&
                        Boolean(formik.errors.sourceName)
                      }
                      helperText={
                        formik.touched.sourceName && formik.errors.sourceName
                      }
                    />
                  )}
                  sx={{ marginBottom: 2 }}
                />
                <Autocomplete
                  id="priceType"
                  name="priceType"
                  disableClearable
                  options={AllpriceType}
                  getOptionLabel={(option) => option.label}
                  value={formik.values.priceType}
                  onChange={(_, newValue) => {
                    formik.setFieldValue("priceType", newValue);
                  }}
                  onBlur={formik.handleBlur}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Select a Price Type"
                      error={
                        formik.touched.priceType &&
                        Boolean(formik.errors.priceType)
                      }
                      helperText={
                        formik.touched.priceType && formik.errors.priceType
                      }
                    />
                  )}
                  sx={{ marginBottom: 2 }}
                />
                <Autocomplete
                  id="currency"
                  name="currency"
                  disableClearable
                  options={["USD - $", "INR - ₹"]}
                  value={formik.values.currency}
                  onChange={(_, newValue) => {
                    formik.setFieldValue("currency", newValue);
                  }}
                  onBlur={formik.handleBlur}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Select a currency Type"
                      error={
                        formik.touched.currency &&
                        Boolean(formik.errors.currency)
                      }
                      helperText={
                        formik.touched.currency && formik.errors.currency
                      }
                    />
                  )}
                  sx={{ marginBottom: 2 }}
                />
                <TextField
                  fullWidth
                  autoComplete="off"
                  variant="standard"
                  label="Amount"
                  name="amount"
                  type="number"
                  value={formik.values.amount}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.amount && Boolean(formik.errors.amount)}
                  helperText={formik.touched.amount && formik.errors.amount}
                  sx={{ marginBottom: 2 }}
                />
                <TextField
                  disabled={defaultValuesLength}
                  fullWidth
                  autoComplete="off"
                  variant="standard"
                  label="URL Endpoint"
                  name="urlEndPonit"
                  value={formik.values.urlEndPonit}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.urlEndPonit &&
                    Boolean(formik.errors.urlEndPonit)
                  }
                  helperText={
                    formik.touched.urlEndPonit && formik.errors.urlEndPonit
                  }
                  sx={{ marginBottom: 2 }}
                />
              </div>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mt: "60px",
              }}
            >
              <Button
                onClick={() => {
                  setIsDrawerOpen(false);
                  formik.resetForm();
                  setDefaultValues({});
                }}
                variant="outlined"
              >
                Cancel
              </Button>
              <Button
                disabled={isButtonDisabled}
                variant="contained"
                type="submit"
              >
                {defaultValuesLength ? "Update" : "Submit"}
              </Button>
            </Box>
            {!defaultValuesLength && (
              <Box sx={{ mt: 8 }}>
                <Box>
                  <Typography fontWeight={800} variant="h6">
                    webhook URl
                  </Typography>
                  {console.log(WebhookData)}
                  <Typography>{WebhookData?.url} </Typography>
                </Box>
                <Button onClick={handleCopyUrl}>copy url</Button>
              </Box>
            )}
          </form>
        </div>
      </Box>
    </Drawer>
  );
};

export default SourceDrawer;
