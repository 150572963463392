import axios from "axios";
import { apiEndPointsConfig } from "./apiEndPointsConfig";
import { axiosInstance, publicAxiosInstance } from "./axiosInstance";
import { gatewayURL } from "../configs/envConfig";

const role = localStorage.getItem("user")
  ? JSON.parse(localStorage.getItem("user"))?.roles[0]
  : "";

const userEmail = localStorage.getItem("user")
  ? JSON.parse(localStorage.getItem("user"))?.email
  : "";
// preset -----------------------company
export const getCompany = async () => {
  try {
    const res = await axiosInstance.get(apiEndPointsConfig.getAllCompany);
    console.log(res, "res");
    return res;
  } catch (err) {
    return err;
  }
};

export const getcurrency = async () => {
  try {
    const res = await axiosInstance.get(apiEndPointsConfig.getcurrency);
    console.log(res, "res");
    return res;
  } catch (err) {
    return err;
  }
};

export const addCompany = async (data) => {
  try {
    const res = await axiosInstance.post(apiEndPointsConfig.addCompany, {
      data,
    });
    return res;
  } catch (err) {
    // return err;
    throw err;
  }
};

export const updateCompany = async (data, id) => {
  try {
    const res = await axiosInstance.put(
      `${apiEndPointsConfig.editCompany}${id}`,
      { data }
    );
    return res;
  } catch (err) {
    throw err;
  }
};
export const deleteCompany = async (id) => {
  try {
    const res = await axiosInstance.post(
      `${apiEndPointsConfig.deleteCompany}${id}`
    );
    return res;
  } catch (err) {
    return err;
  }
};
// preset -----------------------teams

export const getTeams = async () => {
  try {
    const res = await axiosInstance.get(apiEndPointsConfig.getAllTeams);
    console.log(res, "res");
    return res;
  } catch (err) {
    return err;
  }
};
export const addTeam = async (data) => {
  try {
    const res = await axiosInstance.post(apiEndPointsConfig.addTeam, {
      data,
    });
    return res;
  } catch (err) {
    throw err;
  }
};
export const updateTeam = async (data, id) => {
  try {
    const res = await axiosInstance.put(`${apiEndPointsConfig.editTeam}${id}`, {
      data,
    });
    return res;
  } catch (err) {
    throw err;
  }
};
export const deleteTeam = async (id) => {
  try {
    const res = await axiosInstance.post(
      `${apiEndPointsConfig.deleteTeam}${id}`
    );
    return res;
  } catch (err) {
    return err;
  }
};
// preset -----------------------verticals
export const getVerticals = async () => {
  try {
    const res = await axiosInstance.get(apiEndPointsConfig.getAllVerticals);
    console.log(res, "res");
    return res;
  } catch (err) {
    return err;
  }
};
export const addVerttical = async (data) => {
  try {
    const res = await axiosInstance.post(apiEndPointsConfig.addVertical, {
      data,
    });
    return res;
  } catch (err) {
    throw err;
  }
};
export const updateVerttical = async (data, id) => {
  try {
    const res = await axiosInstance.put(
      `${apiEndPointsConfig.editVertical}${id}`,
      {
        data,
      }
    );
    return res;
  } catch (err) {
    throw err;
  }
};
export const deleteVerttical = async (id) => {
  try {
    const res = await axiosInstance.post(
      `${apiEndPointsConfig.deleteVertical}${id}`
    );
    return res;
  } catch (err) {
    return err;
  }
};

// preset -----------------------sources
export const getSource = async () => {
  try {
    const res = await axiosInstance.get(apiEndPointsConfig.getAllSources);
    console.log(res, "res");
    return res;
  } catch (err) {
    return err;
  }
};
export const addSource = async (data) => {
  try {
    const res = await axiosInstance.post(apiEndPointsConfig.addsource, {
      data,
    });
    return res;
  } catch (err) {
    throw err;
  }
};
export const updatesource = async (data, id) => {
  try {
    const res = await axiosInstance.put(
      `${apiEndPointsConfig.editsource}${id}`,
      {
        data,
      }
    );
    return res;
  } catch (err) {
    throw err;
  }
};
export const deletesource = async (id) => {
  try {
    const res = await axiosInstance.post(
      `${apiEndPointsConfig.deletesource}${id}`
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const addUser = async (id) => {
  try {
    const res = await axiosInstance.post(`${apiEndPointsConfig.addUser}`);
    console.log("User ADD");
    return res;
  } catch (err) {
    throw err;
  }
};

// preset -----------------------user --------
export const getuser = async () => {
  try {
    const res = await axiosInstance.get(apiEndPointsConfig.getAllUser);
    console.log(res, "res");
    return res;
  } catch (err) {
    return err;
  }
};
export const getsecureuser = async () => {
  try {
    const token = localStorage.getItem("token");
    // const res = await axios.get(
    //   `${gatewayURL}/secured/get-users`
    // );
    const res = await axios.get(`${gatewayURL}/secured/get-users`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(token)?.access_token}`, // Add the token to the Authorization header
      },
    });
    console.log(res, "res");
    return res;
  } catch (err) {
    return err;
  }
};
export const adduserinteam = async (data) => {
  try {
    const res = await axiosInstance.post(apiEndPointsConfig.adduserinteam, {
      ...data,
    });
    return res;
  } catch (err) {
    throw err;
  }
};
export const updateuserinteam = async (data, id) => {
  try {
    const res = await axiosInstance.put(
      `${apiEndPointsConfig.edituserinteam}${id}`,
      {
        ...data,
      }
    );
    throw res;
  } catch (err) {
    return err;
  }
};
export const deleteuser = async (id) => {
  try {
    const res = await axiosInstance.post(
      `${apiEndPointsConfig.deleteuser}${id}`
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const getUsers = async () => {
  try {
    // const res = await axiosInstanceCore.get(`${apiEndPointsConfig.getUsers}`);
    const res = await axiosInstance.get(apiEndPointsConfig.deleteuser);
    console.log("All Users");
    return res;
  } catch (err) {
    return err;
  }
};
// preset -----------------------api --------
export const addWebhook = async (data) => {
  try {
    const res = await axiosInstance.post(apiEndPointsConfig.addWebhook, {
      ...data,
    });
    return res;
  } catch (err) {
    throw err;
  }
};

export const getApi = async () => {
  try {
    const res = await axiosInstance.get(apiEndPointsConfig.getApi);
    console.log(res, "res");
    return res;
  } catch (err) {
    return err;
  }
};

export const addApi = async (data) => {
  try {
    const res = await axiosInstance.post(apiEndPointsConfig.addApi, {
      data,
    });
    return res;
  } catch (err) {
    throw err;
  }
};
export const updateApi = async (data, id) => {
  try {
    const res = await axiosInstance.put(
      `${apiEndPointsConfig.updateApi}${id}`,
      {
        ...data,
      }
    );
    return res;
  } catch (err) {
    throw err;
  }
};
export const deleteapi = async (id) => {
  try {
    const res = await axiosInstance.post(
      `${apiEndPointsConfig.deleteAPI}${id}`
    );
    return res;
  } catch (err) {
    return err;
  }
};
// cost ----------
export const addCost = async (data) => {
  try {
    const res = await axiosInstance.post(apiEndPointsConfig.addCost, {
      data,
    });
    return res;
  } catch (err) {
    throw err;
  }
};
export const getCost = async () => {
  try {
    const res = await axiosInstance.get(apiEndPointsConfig.getCost);
    console.log(res, "res");
    return res;
  } catch (err) {
    return err;
  }
};
export const updateCost = async (data, id) => {
  try {
    const res = await axiosInstance.put(
      `${apiEndPointsConfig.updateCost}${id}`,
      {
        data,
      }
    );
    return res;
  } catch (err) {
    throw err;
  }
};
