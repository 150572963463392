import {
  Autocomplete,
  Box,
  Button,
  Drawer,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  addvertical,
  addSource,
  addsource,
  getvertical,
  getVerticals,
  updatevertical,
  updatesource,
} from "../../../services/index";
import { enqueueSnackbar } from "notistack";

const validationSchema = Yup.object().shape({
  sourceName: Yup.string()
    .trim()
    .required("Source Name Required")
    .matches(/^[^\s]+(\s[^\s]+)*$/, "Only one space is allowed between words"),
  vertical: Yup.object().required("vertical is required"),
});

const SourceDrawer = ({
  isDrawerOpen,
  setDefaultValues,
  setIsDrawerOpen,
  defaultValues,
  getDataRender,
}) => {
  const defaultValuesLength =
    defaultValues && Object.keys(defaultValues).length;

  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [vertical, setvertical] = useState([]);
  console.log(defaultValues);
  useEffect(() => {
    if (defaultValues !== undefined && Object.keys(defaultValues).length) {
      setFieldValues(defaultValues);
    } else {
      formik.resetForm();
    }
  }, [defaultValues]);

  const getAllVerticals = async () => {
    const { data } = await getVerticals();
    console.log(data?.data, "nnnnnnnnnnnnnnnnnnn");
    if (data?.data?.verticals) {
      setvertical(data?.data?.verticals);
    }
  };

  useEffect(() => {
    getAllVerticals();
  }, []);

  const setFieldValues = (values) => {
    console.log(values, "kkkkkkkkkkkk");
    formik.setFieldValue("sourceName", values?.name);
    console.log(
      vertical,
      "verticalverticalverticalverticalverticalverticalverticalverticalvertical"
    );
    const selectedvertical = vertical.find(
      (cur) => cur._id === values?.vertical[0]?._id
    );
    formik.setFieldValue("vertical", selectedvertical || null);
    setTimeout(() => {
      formik.validateField("sourceName");
    }, 0);
  };

  const formik = useFormik({
    initialValues: {
      sourceName: "",
      vertical: null,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const data = {
        name: values.sourceName.trim(),
        vertical: values.vertical._id,
      };

      try {
        setButtonDisabled(true);
        // debugger
        if (defaultValuesLength === 0) {
          const response = await addSource(data);
          console.log(
            response.response,
            "appppppppppppppppiiiiiiiiiiiresponse......"
          );
          if (response) {
            setIsDrawerOpen(false);
            formik.resetForm();
            setDefaultValues({});
            getDataRender();
          }
        } else {
          const response = await updatesource(data, defaultValues._id);
          if (response) {
            setIsDrawerOpen(false);
            formik.resetForm();
            setDefaultValues({});
            getDataRender();
          }
        }
        // setIsDrawerOpen(false);
        // formik.resetForm();
        // setDefaultValues({});
        // getDataRender();
      } catch (error) {
        console.error("Error submitting vertical data: ", error);
         enqueueSnackbar(`${error?.response?.data?.err}`, {
           variant: "error",
         });
      } finally {
        setButtonDisabled(false);
      }
    },
  });

  return (
    <Drawer anchor="right" open={isDrawerOpen}>
      <Box
        sx={{ m: 1, width: "20vw", margin: "24px" }}
        noValidate
        autoComplete="off"
      >
        <div>
          <form onSubmit={formik.handleSubmit}>
            <Typography
              sx={{
                fontWeight: "600",
                fontSize: "1.3rem",
                mt: 4,
              }}
            >
              {defaultValuesLength ? "Edit" : "Add"} Source
            </Typography>
            <Box>
              <div style={{ width: "330px" }}>
                <TextField
                  fullWidth
                  autoComplete="off"
                  variant="standard"
                  label="Source Name"
                  name="sourceName"
                  value={formik.values.sourceName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.sourceName &&
                    Boolean(formik.errors.sourceName)
                  }
                  helperText={
                    formik.touched.sourceName && formik.errors.sourceName
                  }
                  sx={{ marginBottom: 2 }}
                />
                <Autocomplete
                  id="vertical"
                  name="vertical"
                  disableClearable
                  options={vertical}
                  getOptionLabel={(option) => option?.name}
                  value={formik?.values?.vertical}
                  onChange={(_, newValue) => {
                    formik.setFieldValue("vertical", newValue);
                  }}
                  onBlur={formik.handleBlur}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Select a vertical"
                      error={
                        formik.touched.vertical &&
                        Boolean(formik.errors.vertical)
                      }
                      helperText={
                        formik.touched.vertical && formik.errors.vertical
                      }
                    />
                  )}
                  sx={{ marginBottom: 2 }}
                />
              </div>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mt: "60px",
              }}
            >
              <Button
                onClick={() => {
                  setIsDrawerOpen(false);
                  formik.resetForm();
                  setDefaultValues({});
                }}
                variant="outlined"
              >
                Cancel
              </Button>
              <Button
                disabled={isButtonDisabled}
                variant="contained"
                type="submit"
              >
                {defaultValuesLength ? "Update" : "Submit"}
              </Button>
            </Box>
          </form>
        </div>
      </Box>
    </Drawer>
  );
};

export default SourceDrawer;
